/* 文本溢出 */
%text-es {
  max-width: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: box;
  -webkit-box-orient: vertical;
  word-break: break-word;
  text-overflow: ellipsis;
}

.text-es1 {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-es2 {
  @extend %text-es;
  -webkit-line-clamp: 2;
}

.text-es3 {
  @extend %text-es;
  -webkit-line-clamp: 3;
}