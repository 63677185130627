html,
body {
  font-family: Mircosoft Yahei, "微软雅黑";
  margin: 0;
  padding: 0;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

img {}

.container {
  padding: 0;
  margin: 0 auto;
  width: 1200px;
  @media (max-width: $screen-xs-max) {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    overflow: hidden;
  }
}

.fr {
  float: right;
}

.flf {
  float: left;
}