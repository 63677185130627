// header
.hd {
  line-height: 90px;
  background-color: #1b5ca2;
  color: #fff;
  @media (max-width: $screen-xs-max) {
    display: none;
  }
}

.hd-logo {
  margin-right: 80px;
  display: inline-block;
  vertical-align: middle;
}

.hd-nav {
  display: inline-block;
  vertical-align: middle;
  a {
    display: inline-block;
    color: #fff;
    width: 170px;
    text-align: center;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 20px;
      background: #5687bb;
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}

.hd-tel {
  float: right;
  img {
    vertical-align: -5px;
    margin-right: 5px;
  }
}

// header-mobile
.hd-mb {
  display: none;
  padding: 0 15px;
  height: 50px;
  line-height: 50px;
  background-color: #1b5ca2;
  @media (max-width: $screen-xs-max) {
    display: block;
  }
}

.hd-logo-mb {
  vertical-align: middle;
  max-height: 40px;
}

.hd-menu-mb {
  float: right;
  font-size: 20px;
  color: white;
  &:hover,
  &:active,
  &:focus {
    color: white;
  }
}

.hd-nav-mb {
  background-color: lighten(#1b5ca2, 2);
  border-top: 1px solid lighten(#1b5ca2, 2);
  a {
    padding: 0 15px;
    display: block;
    line-height: 40px;
    height: 40px;
    color: white;
  }
}

// footer 
.footer {
  background-color: #222222;
}

.footer-bd {
  padding-top: 65px;
  padding-bottom: 20px;
  @media (max-width: $screen-xs-max) {
    padding-top: 10px;
  }
  .area-top {
    margin-left: -45px;
    @media (max-width: $screen-xs-max) {
      display: none;
    }
    dl {
      position: relative;
      padding-left: 45px;
      width: 155px;
      float: left;
      &:nth-child(6) {
        &::after {
          display: none;
        }
      }
      &::after {
        position: absolute;
        right: 0;
        top: 0;
        content: '';
        width: 3px;
        height: 210px;
        background-repeat: no-repeat;
        background-position: right top;
        background-image: url(../img/footer-line-h_03.png);
      }
      dt {
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: normal;
        color: #ffffff;
      }
      dd {
        line-height: 1.75;
        a {
          font-size: 14px;
          color: #8e8e8e;
        }
      }
      &.contact {
        width: 230px;
      }
    }
  }
  .area-btm {
    margin-top: 15px;
    span {
      color: #777777;
    }
    nav {
      display: inline-block;
      a {
        color: #8e8e8e;
        margin-right: 10px;
      }
    }
  }
  .ewm {
    margin-top: -15px;
    width: 230px;
    text-align: center;
    .bn {
      margin-left: 15px;
      margin-top: 25px;
    }
  }
}

.footer-ft {
  color: #777777;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: #101010;
  overflow: hidden;
  .logo {
    float: right;
  }
}

.img-wrap {
  img {
    width: 100%;
  }
}