// btn namespace
$btn-ns: "bn" !default;
// variables
// variables -- base
$btn-c: #666 !default;
$btn-bc: #999 !default; // background-color and border-color
$btn-h: 35px !default;
$btn-lh: 33px !default;
$btn-ph: 20px !default; // padding-left and padding-right
$btn-pv: 0; //paddind-top and padding-bottom
$btn-radius: 1px !default;
$btn-animate: true !default;
// variables -- color
$btn-colors: ( 'white': ( bc: #fff, fc: #666), 'blue': ( bc: #1b5ca2, fc: #fff), 'red': ( bc: #be3228, fc: #fff), 'red-l': ( bc: #f2dfde, fc: #be3228), 'red-d': ( bc: #c10b0b, fc: #fff)) !default;
// variables -- shapes
$btn-shapes: ( square: 0, round: 6px, box: 10px, pill: 200px) !default;
// mixin
// mixin -- color
@mixin btn-variant($c: $btn-c, $bg: transparent, $bd: transparent) {
  color: $c;
  background-color: $bg;
  border-color: $bd;
  &:visited {
    color: $c;
  }
  &:hover,
  &:focus,
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    background-color: lighten($bg, 10%);
    border-color: lighten($bd, 10%);
    color: $c;
  }
  &:active,
  &.active,
  &.is-active {
    background-color: darken($bg, 10%);
    border-color: darken($bd, 10%);
    color: darken($c, 10%);
  }
}

// mixin -- btn-bd
@mixin btn-bd($bc: $btn-bc) {
  color: $bc;
  background: none;
  border-color: $bc;
}

.#{$btn-ns}-bd {
  background: none;
  border-width: 1px;
  border-style: solid;
  &:hover {
    background-color: rgba(lighten($btn-bc, 10%), .9)
  }
  &:active,
  &.active,
  &.is-active {
    box-shadow: none;
    text-shadow: none;
  }
}

// class
.#{$btn-ns} {
  display: inline-block;
  margin-bottom: 0;
  padding: $btn-pv $btn-ph;
  height: $btn-h;
  line-height: $btn-lh;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  border-radius: $btn-radius;
  white-space: nowrap;
  @if $btn-animate {
    transition-property: all;
    transition-duration: .3s;
  }
  @include btn-variant($btn-c, transparent, $btn-bc);
  &:hover,
  &:focus,
  &.focus {
    color: $btn-c;
    text-decoration: none;
  }
  &:active,
  &.active {
    outline: 0;
    // background-image: none;
    box-shadow: inset 1px 3px 8px rgba(0, 0, 0, .075);
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    top: 0 !important;
    background: #EEE !important;
    border: 1px solid #DDD !important;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 1) !important;
    color: #CCC !important;
    cursor: default !important;
    appearance: none !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .10);
    opacity: .8;
  }
}

a.#{$btn-ns} {
  &.disabled,
  fieldset[disabled] & {
    pointer-events: none;
  }
}

// class
// class -- colors
@each $key,
$value in $btn-colors {
  $btn-name: $key;
  $btn-bc: map-get($value, bc);
  $btn-fc: map-get($value, fc);
  .#{$btn-ns}-bd-#{$btn-name} {
    @include btn-variant($btn-bc, transparent, $btn-bc);
  }
  .#{$btn-ns}-bg-#{$btn-name} {
    &.#{$btn-ns}-bd {
      @include btn-bd($btn-bc);
    }
    @include btn-variant($btn-fc, $btn-bc, $btn-bc);
  }
}

// class -- shapes
@each $key,
$value in $btn-shapes {
  $btn-name: $key;
  $btn-radius: $value;
  .#{$btn-ns}-#{$btn-name} {
    border-radius: $btn-radius;
  }
}